
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import TopBar from "@/components/top-bar/Main.vue";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "./index";
import { authService } from "@/services";

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    SideMenuTooltip,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const user = authService.getUserInfoLocal();

    const sideMenu = computed(() => {
      let menu = store.state.sideMenu.menu;
      if (user?.role === "CHILDREN") {
        menu = store.state.sideMenu.menuChildren;
      }
      if (user?.role === "CARE") {
        menu = store.state.sideMenu.menuCare;
      }
      if (["buivantue1", "buivantue2"].includes(user?.userName || "")) {
        menu = menu.map((item) => ({ ...item, subMenu: item.subMenu?.filter((sub) => sub.pageName !== "money-line") }));
      }
      return nestedMenu(menu, route);
    });

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    onMounted(() => {
      cash("body").removeClass("error-page").removeClass("login").addClass("app");
      formattedMenu.value = $h.toRaw(sideMenu.value);
    });

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
    };
  },
});
