
import { defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { Menu } from "@/layouts/side-menu";
import { authService } from "@/services";

export default defineComponent({
  name: "Pagination",
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const findActiveMenu = (subMenu: Array<Menu|string>, name: any): string => {
      let match = "";
      subMenu.forEach((item) => {
        if (typeof item === "string") {
          return "";
        }
        if (item.pageName === name && !item.ignore) {
          match = item.title;
        } else if (!match && item.subMenu) {
          match = findActiveMenu(item.subMenu, name);
        }
      });
      return match;
    };

    const display = ref<string>(
      findActiveMenu(store.state.sideMenu.menu, route.name)
    );

    const loggedInUser = authService.getUserInfoLocal();
    const userName = ref<string | undefined>(loggedInUser?.fullName);

    const logout = (): void => {
      authService.logout();
      router.push({ name: "login" });
    };

    watch(
      () => route.name,
      (newName: any) => {
        display.value = findActiveMenu(store.state.sideMenu.menu, newName);
      }
    );
    return { display, userName, logout };
  },
});
