<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <router-link :to="{ name: 'side-menu-dashboard' }" tag="a">
        Trang chủ
      </router-link>
      <ChevronRightIcon class="breadcrumb__icon" />
      <a href class="breadcrumb--active">{{ display }}</a>
    </div>
    <!-- END: Breadcrumb -->
    <div class="mr-2">
      <span>{{ userName }}</span>
    </div>
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
      >
        <img
          alt="Midone Tailwind HTML Admin Template"
          :src="require(`@/assets/images/logo.png`)"
        />
      </div>
      <div class="dropdown-box w-56">
        <div class="dropdown-box__content box bg-dark-6 text-white">
          <div class="p-4 border-b border-dark-3">
            <div class="font-medium">{{ userName }}</div>
          </div>
          <div class="p-2">
            <router-link
              :to="{ name: 'sender-address' }"
              tag="a"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> Địa chỉ gửi hàng
            </router-link>
            <router-link
              :to="{ name: 'change-password' }"
              tag="a"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
            >
              <LockIcon class="w-4 h-4 mr-2" /> Đổi mật khẩu
            </router-link>
          </div>
          <div class="p-2 border-t border-dark-3">
            <a
              href
              @click="logout"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 rounded-md"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" />
              Đăng xuất
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { Menu } from "@/layouts/side-menu";
import { authService } from "@/services";

export default defineComponent({
  name: "Pagination",
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const findActiveMenu = (subMenu: Array<Menu|string>, name: any): string => {
      let match = "";
      subMenu.forEach((item) => {
        if (typeof item === "string") {
          return "";
        }
        if (item.pageName === name && !item.ignore) {
          match = item.title;
        } else if (!match && item.subMenu) {
          match = findActiveMenu(item.subMenu, name);
        }
      });
      return match;
    };

    const display = ref<string>(
      findActiveMenu(store.state.sideMenu.menu, route.name)
    );

    const loggedInUser = authService.getUserInfoLocal();
    const userName = ref<string | undefined>(loggedInUser?.fullName);

    const logout = (): void => {
      authService.logout();
      router.push({ name: "login" });
    };

    watch(
      () => route.name,
      (newName: any) => {
        display.value = findActiveMenu(store.state.sideMenu.menu, newName);
      }
    );
    return { display, userName, logout };
  },
});
</script>
